import React from 'react';
import Layout from "../components/Layout";
import {Container} from "@material-ui/core";
import GalleryGrid from "../components/GalleryGrid";

function Gallery() {
  return (
    <Layout>
      <Container maxWidth="md">
        <h1>Gallery</h1>
        <GalleryGrid images={[]} />
      </Container>
    </Layout>
  );
}

export default Gallery;
