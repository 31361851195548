import React from 'react';
import Gallery from 'react-grid-gallery';
import {graphql, useStaticQuery} from "gatsby";

interface Props {
  images: {
    src: string;
    thumbnail: string;
  }[]
}

function GalleryGrid(props: Props) {
  const data = useStaticQuery(graphql`
    query GalleryGridQuery {
      allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "photos"}}) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const photos = data.allFile.edges.map(edge => edge.node.childImageSharp.fluid);
  const thumbnailHeight = 250;
  const images = photos.map(photo => {
    return { src: photo.src, thumbnail: photo.src, thumbnailWidth: thumbnailHeight * photo.aspectRatio, thumbnailHeight }
  });
  return (
    <Gallery images={images} backdropClosesModal />
  );
}

export default GalleryGrid;
